import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import NewSourceForm from "./NewSourceForm";


class NewSourceModal extends React.Component {
	state = {
		error: {},
	}

	submitHandler = event => {
		event.preventDefault()
	}

	componentDidMount() {
		// this.props.resetMessage()
	}

	changeHandler = event => {
		this.setState({
			[event.target.name]: event.target.value
		})
	}
	render() {
		const customStyles = {
			content: {
				top: '50%',
				left: '50%',
				right: 'auto',
				bottom: 'auto',
				marginRight: '-50%',
				width: '580px',
				transform: 'translate(-50%, -50%)'
			}
		};

		let { orderInfo, serviceInfo } = this.props

		return (
			<Modal
				isOpen={this.props.isOpen}
				onRequestClose={this.props.close}
				style={customStyles}
			>
				<div className="new-order">
					<div className="modal-header">
						<div className="header-title">Add new source</div>
						<div className="clone-actions" onClick={() => this.props.onRequestClose.call()} >X</div>
					</div>
					<div className="m-0 col-md-12 modal-body">
						<NewSourceForm orderInfo={orderInfo} serviceInfo={serviceInfo} />
					</div>
				</div>
			</Modal>
		)
	}
}


const mapStateToProps = state => ({
	business: state.business,
	sources: state.source,
	categories: state.category,
})
const mapDispatchToProps = ({
})
export default connect(mapStateToProps, mapDispatchToProps)(NewSourceModal);
