import axios from "axios";
import * as Types from "./types";
import configureAxios from "../utils/configureAxios";

import { showSuccess, showError } from "../utils/configToast";
import { manageError } from "../utils/axiosResponseError";

import { setDefaultUserData } from "./userData";
import {USER_ACCESS_STATUS} from "./types";

export const registrationAction =
	(user, history, stopLoading) => (dispatch) => {
		axios
			.post("user/registration", user)
			.then((res) => {
				stopLoading();

				let token = res.data.Session;
				let userData = res.data.User;

				localStorage.setItem("authToken", token);

				configureAxios(); // Set token to axios request header configure

				dispatch({
					type: Types.SET_USER,
					payload: {
						user: userData,
						error: {},
					},
				});
				history.push("/");
			})
			.catch((error) => {
				stopLoading();
				manageError(error);
			});
	};

export const updateUser = (user, history, stopLoading) => (dispatch) => {
	axios
		.post("user/update", user)
		.then((res) => {
			stopLoading();
			let { MsgSuccess } = res.data;
			let successMsg = MsgSuccess;
			showSuccess(successMsg);

			let userData = res.data.user;

			dispatch({
				type: Types.SET_USER,
				payload: {
					user: userData,
					error: {},
				},
			});
		})
		.catch((error) => {
			stopLoading();
			manageError(error);
		});
};

export const updatePassword =
	(user, history, stopLoading, resetPassFormData) => (dispatch) => {
		axios
			.post("user/change-password", user)
			.then((res) => {
				stopLoading();
				let { MsgSuccess } = res.data;
				let successMsg = MsgSuccess;
				showSuccess(successMsg);

				resetPassFormData();

				// let userData = res.data.data-source

				// dispatch({
				// 	type: Types.SET_USER,
				// 	payload: {
				// 		data-source: userData,
				// 		error: {}
				// 	}
				// })
			})
			.catch((error) => {
				stopLoading();
				let errorData;
				if (error.response) {
					errorData = error.response.data.join(", ");
				} else {
					errorData = "No response?";
				}
				showError(errorData);
			});
	};

export const login = (user, history, stopLoading) => (dispatch) => {
	axios
		.post("user/login", user)
		.then(async (res) => {
			stopLoading();
			let token = res.data.Session;
			let userData = res.data.User;

			localStorage.setItem("authToken", token);

			await configureAxios(); // Set token to axios request header configure

			await dispatch({
				type: Types.SET_USER,
				payload: {
					user: userData,
					error: {},
				},
			});
			dispatch(setDefaultUserData()); // Load data-source basic data

			history.push("/");
		})
		.catch((error) => {
			stopLoading();
			manageError(error);
		});
};

export const setUserByToken = (user, history, stopLoading) => (dispatch) => {
	axios
		.post("user/set-user", user)
		.then(async (res) => {
			stopLoading();
			let token = res.data.Session;
			let userData = res.data.User;

			localStorage.setItem("authToken", token);

			await configureAxios(); // Set token to axios request header configure

			await dispatch({
				type: Types.SET_USER,
				payload: {
					user: userData,
					error: {},
				},
			});
			dispatch(setDefaultUserData()); // Load data-source basic data

			history.push("/");
		})
		.catch((error) => {
			stopLoading();
			manageError(error);
		});
};

export const resetRequest =
	(user, history, stopLoading, resetForm) => (dispatch) => {
		axios
			.post("user/reset-request", user)
			.then(async (res) => {
				let { MsgSuccess } = res.data;
				showSuccess(MsgSuccess, "top-left");

				stopLoading();
				resetForm();
				// history.push('/reset-password')
			})
			.catch((error) => {
				stopLoading();
				manageError(error);
			});
	};

export const resetPassword = (user, history, stopLoading) => (dispatch) => {
	axios
		.post("user/reset-password", user)
		.then(async (res) => {
			let { MsgSuccess } = res.data;

			showSuccess(MsgSuccess, "top-left");
			stopLoading();
			history.push("/login");
		})
		.catch((error) => {
			stopLoading();
			manageError(error);
		});
};

export const logout = (history) => (dispatch) => {
	localStorage.removeItem("authToken");

	// Reset data-source data
	dispatch({
		type: Types.SET_USER,
		payload: {
			user: {},
		},
	});
	// dispatch({
	// 	type: Types.USER_SET_VIEW_MODE,
	// 	payload: null,
	// });

	dispatch(
		setViewMode(null)
	);

	// history.push("/login");
};

export const isLoggedIn = (accessToken) => async (dispatch) => {
	let authToken = localStorage.getItem("authToken");
	if(accessToken){
		authToken = accessToken;
	}

	if (!authToken) {
		// showError('Token not found');
		setAccessStatus("notAuthenticated")(dispatch);
		return;
	}

	// Update loading status
	dispatch({
		type: Types.USERS_AUTH_CHECKING,
		payload: {
			status: true,
		},
	});

	axios
		.get("/auth", { headers: { Authorization: authToken } })
		.then(async (res) => {
			
			// 
			if(accessToken){
				console.log('Will it work?');
				localStorage.setItem("authToken", accessToken);
				await configureAxios(); // Set token to axios request header configure
			}

			// Update loading status
			dispatch({
				type: Types.USERS_AUTH_CHECKING,
				payload: {
					status: false,
				},
			});

			let userData = res.data;
			dispatch({
				type: Types.SET_USER,
				payload: {
					user: userData,
					error: {},
				},
			});



			setViewMode("shopAdmin")(dispatch)
			setAccessStatus("authenticated")(dispatch);

			// processForLoggedInUser()
			dispatch(setDefaultUserData()); // Load data-source basic data

			// history.push('/')
		})
		.catch((error) => {
			// Update loading status
			dispatch({
				type: Types.USERS_AUTH_CHECKING,
				payload: {
					status: false,
				},
			});

			if (!error.response) {
				return;
			}

			dispatch({
				type: Types.USERS_ERROR,
				payload: {
					error: error.response.data,
				},
			});
			setAccessStatus("notAuthenticated")(dispatch);

			manageError(error);
		});

	return;
};

export const setViewMode = (viewMode) => async (dispatch) => {
	dispatch({
		type: Types.USER_SET_VIEW_MODE,
		payload: {
			viewMode,
		},
	});
};

export const setAccessStatus = (accessStatus) => async (dispatch) => {
	dispatch({
		type: Types.USER_ACCESS_STATUS,
		payload: accessStatus,
	});
};
