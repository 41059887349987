import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import SideMenu from "../../components/menu/sideMenu";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import ShowSources from "../../components/data-source/showSources";
import NewSourceModal from "../../components/data-source/NewSourceModal";

class SourceAllPage extends React.Component {
	insetModalStatus = false;
	constructor(props) {
		super(props);
		this.state = {
			insetModalStatus: false,
		};
	}

	setPageData() {
		this.props.setPageData({
			title: "Product source",
			pageID: "home_page",
			totalUsers: "...",
		});
	}

	componentDidMount() {
		this.setPageData();
	}

	componentWilMount() {}

	openInsertModal() {
		this.setState({
			insetModalStatus: true,
		});
	}
	closeInsertModal() {
		this.setState({
			insetModalStatus: false,
			reloadDataID: Date.now(),
		});
	}

	render() {
		this.props.bodyClassController(this.props.pageData);
		let { pageData, userData } = this.props;
		let { insetModalStatus, reloadDataID } = this.state;

		let userSummery;

		if (userData) {
			userSummery = userData.summery;
		}
		return (
			<>
				<Helmet><title>{pageData.title}</title></Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>Import sources / files</h1>
									</div>
									<span
										onClick={() => this.openInsertModal()}
										className="btn btn-primary ml-3">
										<span className="icon-wrapper">
											<i className="icofont-plus-circle"></i>
										</span>
										<span className="icon-text"> Add New</span>
									</span>
								</div>

								<div className="page-content">
									<ShowSources paymentStatus={null} reloadDataID={reloadDataID}/>
								</div>

								<NewSourceModal isOpen={insetModalStatus} onRequestClose={() => this.closeInsertModal()}/>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}


const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
};
export default connect(mapStateToProps, mapDispatchToProps)(SourceAllPage);
