import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";
import ReactPaginate from "react-paginate";
import { ViewDate, ViewExactDate } from "../../utils/DateViewer";
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';

import bodyClassController from "../../store/utils/bodyClassController";

import setPageData from "../../store/actions/setPageData";
import { retrieveProductsAction } from "../../store/actions/productActions";

class ShowProducts extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ReOrderModalOpen: null,
			expandedItem: null,
			NewTicketModalOpen: false,
			accountType: "",
			autoRenew: false,
			paymentStatus: "activeSubscription",
			advanceFilter: false,
			dataLoading: false,
			orderBy: "date",
			orderSort: "DESC",
			promoCode: "",
			keyword: "",
			error: {},
			page: 1,
		};
	}
	changeHandler = async (event) => {
		let stateName, stateValue;
		if (event.target.type == "checkbox") {
			stateName = event.target.name;
			stateValue = event.target.checked;
		} else {
			stateName = event.target.name;
			stateValue = event.target.value;
		}

		this.setState(
			{
				[stateName]: stateValue,
			},
			() => {
				console.log("type", event.target.type);
				if (
					event.target.type === "checkbox" ||
					event.target.type === "select-one"
				) {
					this.submitHandler();
				}
			}
		);
	};

	submitHandler = async (event) => {
		if (event != null) {
			event.preventDefault();
		}

		this.setState({
			dataLoading: true,
		});

		let {
			page,
			keyword,
			orderBy,
			orderSort,
		} = this.state;
		let {type, sourceID} = this.props;
		await this.props.retrieveProductsAction({
			page,
			type,
			sourceID,
			keyword,
			orderBy,
			orderSort,
		});

		this.props.setPageData({
			...this.props.pageData,
			totalUsers: this.props.userData.pagination.data_total,
		});

		this.setState({
			dataLoading: false,
		});
	};

	// set data sorting //
	setSorting = (orderBy, orderSort) => {
		this.setState({ orderBy, orderSort }, () => {
			this.submitHandler();
		});
	};

	componentDidMount() {
		this.setState(
			{
				paymentStatus: this.props.paymentStatus,
			},
			() => {
				this.submitHandler();
			}
		);
	}

	componentWilMount() {}

	componentDidUpdate(prevProps) {
		if(prevProps.type != this.props.type){
			this.submitHandler()
		}
	}

	handlePagination = (data) => {
		this.setState(
			{
				page: data.selected + 1,
			},
			() => {
				// this.props.retrieveUsers({ page: this.state.page })
				this.submitHandler();
			}
		);
	};

	onVisibleChange = (visible) => {
		console.log(visible);
	}

	DropdownItems(item) {
		return (
			<div className="my-dropdown-items">
				<Link to={`/products/settings/${item.id}`} className="dropdown-item">
					Settings
				</Link>
				{/* <div className="dropdown-item">Upload</div>
				<div className="dropdown-item">Sync</div>
				<div className="dropdown-item text-danger">Delete</div> */}
			</div>
		);
	}

	render() {
		this.props.bodyClassController(this.props.pageData);

		let { productData } = this.props;

		let allProducts, userPagination, showPagination;
		if (
			productData &&
			productData.data &&
			productData.pagination.data_total > 0
		) {
			allProducts = productData.data.length ? productData.data : null;
			userPagination = productData.pagination ? productData.pagination : null;
			showPagination = userPagination.page_last > 1;
		}
		return (
			<div className="table-area table-responsive">
				<div className="table-responsive">
					<table className="table table-striped ">
						<thead>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Product title</th>
								<th scope="col">Source</th>
								<th scope="col">Status</th>
								<th scope="col" className="text-right">Actions</th>
							</tr>
						</thead>
						<tbody>
						{allProducts
							? allProducts.map((item) => {
								return (

									<tr>
										<th scope="row">
											<Link to={`/users/${item.id}`} > {item.id} </Link>
										</th>
										<td  style={{maxWidth:'350px'}} >

											{item.title}

										</td>
										<td>
											
											<Link className="badge badge-info" to={`/sources/details/${item.sourceID}`} >{item.sourceID}</Link>
										{/* <span className="badge badge-info"> </span> */}

										</td>
										<td>{item.status}</td>
										<td className="text-right">
											<Dropdown
												trigger={['click']}
												overlay= {this.DropdownItems(item)}
												animation="slide-up"
												onVisibleChange={() => this.onVisibleChange()}
											>
												<font className="my-dropdown" style={{ width: 100 }}>action <i className="icofont-caret-down"></i></font>
											</Dropdown>
										</td>
									</tr>
								);
							})
							: ""}
						</tbody>
					</table>
				</div>

				<div className="section-pagination">
					<div className="data-info">
						Showing {userPagination?.data_start} to{" "}
						{userPagination?.data_end} out of{" "}
						{userPagination?.data_total}
					</div>
					{showPagination && (
						<nav className="pagination-nav" aria-label="...">
							<ReactPaginate
								previousLabel={"previous"}
								nextLabel={"next"}
								breakLabel={"..."}
								breakClassName={"break-me"}
								pageCount={userPagination.page_last}
								initialPage={userPagination.page - 1}
								marginPagesDisplayed={2}
								pageRangeDisplayed={5}
								containerClassName={"pagination"}
								previousClassName={"page-item"}
								nextClassName={"page-item"}
								pageClassName={"page-item"}
								subContainerClassName={"pages pagination"}
								activeClassName={"active"}
								pageLinkClassName={"page-link"}
								previousLinkClassName={"page-link"}
								nextLinkClassName={"page-link"}
								onPageChange={this.handlePagination}
							/>
						</nav>
					)}
				</div>
			</div>
		);
	}

	openReOrderModal = (orderInfo) => {
		this.setState({
			ReOrderModalOpen: orderInfo,
		});
	};
	closeReOrderModal = () => {
		this.setState({
			ReOrderModalOpen: null,
		});
	};

	openNewTicketModal = (item) => {
		this.setState({
			NewTicketModalOpen: item.OrderID,
		});
	};

	closeNewTicketModal = () => {
		this.setState({
			NewTicketModalOpen: false,
		});
	};
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	productData: state.productData,
});
const mapDispatchToProps = {
	logout,
	bodyClassController,
	retrieveProductsAction,
	setPageData,
};
export default connect(mapStateToProps, mapDispatchToProps)(ShowProducts);
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
