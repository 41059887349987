import React from "react";
import { connect } from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import { isLoggedIn } from "../../store/actions/authActions";
import configureAxios from "../../store/utils/configureAxios";
import { useSearchParams } from "react-router-dom";

export function withRouter( Child ) {
	return ( props ) => {
		const params = useParams();
		const navigate = useNavigate();
		const [searchParams, setSearchParams] = useSearchParams();
		return <Child { ...props } params ={ params } searchParams ={ searchParams } />;
	}
}

class AuthChecking extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			accessToken: this.props.searchParams.get('token'),
			error: {},
		};
	}

	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	submitHandler = (event) => {
		event.preventDefault();

		this.props.logout(this.props.history);
	};

	async authCheck() {
		await configureAxios(); // Run Basic Axios Configure
		this.props.isLoggedIn(this.state.accessToken);
	}

	forceLogin = () => {

		// let { isAuthenticated, authChecking } = this.props.auth;
		// if (!authChecking && !isAuthenticated) {
		// 	navigate("/login")
		// 	// .this.props.history.push("/login");
		// }
	};
	componentDidMount() {
		this.authCheck();
	}

	render() {
		// console.log(this.state.accessToken);
		// this.authCheck();
		return (
			<div className="page-auth-checking">
				<div className="checking-content">
					<div className="footer">
						<i className="fas fa-spinner fa-spin"></i> Loading...
					</div>
				</div>
			</div>
		);
	}
}


const mapStateToProps = (state) => ({
	auth: state.auth,
});
const mapDispatchToProps = {
	isLoggedIn,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthChecking));


