import React from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import ReactGA from "react-ga4";

import LoginPage from "./pages/auth/LoginPage";


import AuthChecking from "./components/default/authChecking";

import { connect } from "react-redux";

import { isLoggedIn } from "./store/actions/authActions";

import configureAxios from "./store/utils/configureAxios";

import PrimaryMenu from "./components/menu/headerPrimaryMenu";
import FooterPrimary from "./components/footer/footerPrimary";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
import ResetPasswordPage from "./pages/auth/ResetPasswordPage";
import SourceAllPage from "./pages/data-source/SourceAllPage";
import ProductsAllPage from "./pages/products/ProductsAllPage";
import SourceDetailsPage from "./pages/data-source/SourceDetailsPage";
import TokenChecking from "./components/default/tokenChecking";
import AuthRequired from "./components/default/authRequired";
import PricingPage from "./pages/plan/PricingPage";
import SourceSettingsPage from "./pages/settings/SourceSettingsPage";
import ProductSettingsPage from "./pages/products/ProductSettingsPage";

class MyRoutes extends React.Component {
	state = {
		user: "",
		viewMode: null,
		password: "",
		error: {},
	};
	async componentWillMount() {
		await configureAxios() // Run Basic Axios Configure
	}

	componentDidMount() {}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			JSON.stringify(prevProps.auth) !== JSON.stringify(this.props.auth)
		) {
			let viewAsAgent = localStorage.getItem("viewAsAgent");

			let { user } = this.props.auth;

			let viewMode;

			if (user && user.type === "admin" && viewAsAgent === "1") {
				viewMode = "affiliate";
			} else if (user && user.type === "admin") {
				viewMode = "admin";
			}
		}
	}

	render() {
		let { isAuthenticated, accessStatus } = this.props.auth;
		let { viewMode } = this.props.auth;
		// let isAuthenticated = true;
		// let viewMode = 'admin';
		
		const trackingID = "G-XJ4NYQXNTL";
		ReactGA.initialize(trackingID);
		ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: "Root Page" });



		return (
			<BrowserRouter>
				{(() => {
					if (accessStatus === "notAuthenticated") {
						return (
							<Routes>
								<Route path="/*" element={<AuthRequired/>} />
							</Routes>
						);
					}else if (viewMode === "shopAdmin") {
						return (
							<Routes>
								<Route path="/" element={ <Navigate to="/sources" /> } />

								<Route path="/sources" element={<SourceAllPage />} />
								<Route path="/sources/details/:sourceID" element={<SourceDetailsPage />} />

								<Route path="/products/queued" element={<ProductsAllPage type="queued" />} />
								<Route path="/products/imported" element={<ProductsAllPage type="imported" />} />
								<Route path="/products/failed" element={<ProductsAllPage type="failed" />} />
								<Route path="/products/settings/:productID" element={<ProductSettingsPage type="failed" />} />

								<Route path="/settings/source" element={<SourceSettingsPage/>} />
								<Route path="/pricing" element={<PricingPage/>} />
							</Routes>
						);
					} else {
						return (
							<Routes>
								<Route path="/*" element={<AuthChecking/>} />
								{/*<Route path="/" element={<LoginPage/>} />*/}
								<Route path="/login" element={<LoginPage/>} />
								<Route path="/set-user" element={<TokenChecking/>} />
								<Route path="/forgot-password" element={<ForgotPasswordPage/>} />
								<Route path="/reset-password" element={<ResetPasswordPage/>} />
							</Routes>
						);
					}
				})()}
			</BrowserRouter>
		);
	}
}

// export default App;

const mapStateToProps = (state) => ({
	auth: state.auth,
});
const mapDispatchToProps = {
	isLoggedIn,
	PrimaryMenu,
	FooterPrimary,
};
export default connect(mapStateToProps, mapDispatchToProps)(MyRoutes);