import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

class PrimaryMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: "",
			password: "",
			error: {},
		};
	}

	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	submitHandler = (event) => {
		event.preventDefault();

		this.props.logout(this.props.history);
	};

	render() {
		let { auth } = this.props;
		let { user, viewMode } = auth;
		let shopName;

		try {
			shopName = auth.user.shop.name;
		} catch (error) {
			console.log("Username", error);
		}
		return (
			<nav className="navbar navbar-expand-lg sticky-top navbar-light bg-light">
				<Link to="/" className="navbar-brand">
					{/*<img src={imgLogo} className="logo" alt="" />*/}
					Prism - Product Importer
				</Link>
				<div
					className="collapse navbar-collapse"
					id="navbarSupportedContent"
				>
					<ul className="navbar-nav mr-auto" />

					<ul className="navbar-nav">
						<li className="nav-item dropdown ml-auto mobile-hide">
							<div
								className="nav-link dropdown-toggle"
								id="navbarDropdown"
								role="button"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<span>{shopName}</span>

								<div
									className="dropdown-menu"
									aria-labelledby="navbarDropdown">
									<font
										className="dropdown-item "
										onClick={this.submitHandler}
										href="#"
									>
										Logout
									</font>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</nav>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	logout,
};
export default connect(mapStateToProps, mapDispatchToProps)(PrimaryMenu);
