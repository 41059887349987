import axios from "axios";
import * as Types from "./types";

import { showSuccess, showError } from "../utils/configToast";
import { manageError } from "../utils/axiosResponseError";

export const retrieveSettingsAction = (data) => async (dispatch) => {
	let responseData;
	await axios
		.get("/my-settings", {params: data})
		.then((res) => {
			responseData = res.data.data;
			dispatch({
				type: Types.SETTINGS_RETRIEVE,
				payload: {
					response: responseData,
					error: {},
				},
			});
		})
		.catch((error) => {
			manageError(error);
		});
	return responseData;
};


export const putSettingsAction = (data) => async (dispatch) => {
	let responseType;
	await axios
		.put("/my-settings", data)
		.then((res) => {
			let promoInfo = res.data.data;

			showSuccess(res.data.message);

			dispatch({
				type: Types.SETTINGS_UPDATE,
				payload: {
					promoInfo,
					error: {},
				},
			});
			responseType = true;
		})
		.catch((error) => {
			manageError(error);
		});
	return responseType;
};