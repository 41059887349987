import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import Icofont from "react-icofont";

class SideMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: "",
			password: "",
			error: {},
		};
	}

	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	submitHandler = (event) => {
		event.preventDefault();

		this.props.logout(this.props.history);
	};

	render() {
		let { pageID } = this.props.pageData;
		let { user } = this.props.auth;
		return (
			<div className="col-md-2 side-menu">
				<nav className="collapse show sidebar-nav pt-4" id="left-nav">
					{/*<div className="nav-item active" >*/}
						<NavLink to="/sources"
								 className={(navInfo) => navInfo.isActive ? "nav-item nav-link active" : "nav-item nav-link"}>
							<i className="icofont-database"></i>
							<span className="icon-text">Source / files</span>
						</NavLink>
					{/*</div>*/}

					<ui className="nav d-block">
						<li className="nav-item  collapsed" data-bs-toggle="collapse" data-bs-target="#products-collapse">
							<span className="nav-link">
								<i className="icofont-list"></i>
								<span className="icon-text">Products</span>
							</span>
						</li>

						<div className="collapse show" id="products-collapse">
							<NavLink to="/products/queued"
									 className={(navInfo) => navInfo.isActive ? "nav-item sub nav-link active" : "nav-item sub nav-link"}>
								<i className="icofont-clock-time text-warning"></i>
								<span className="icon-text">Queued</span>
							</NavLink>
							<NavLink to="/products/imported"
									 className={(navInfo) => navInfo.isActive ? "nav-item sub nav-link active" : "nav-item sub nav-link"}>
								<i className="icofont-tick-mark text-success"></i>
								<span className="icon-text">Imported</span>
							</NavLink>
							<NavLink to="/products/failed"
									 className={(navInfo) => navInfo.isActive ? "nav-item sub nav-link active" : "nav-item sub nav-link"}>
								<i className="icofont-warning text-danger"></i>
								<span className="icon-text">Failed</span>
							</NavLink>
						</div>
					</ui>

					<ui className="nav d-block">
						<li className="nav-item  collapsed" data-bs-toggle="collapse" data-bs-target="#settings-collapse">
							<span className="nav-link">
								<i className="icofont-settings-alt"></i>
								<span className="icon-text">Settings (global)</span>
							</span>
						</li>

						<div className="collapse show" id="settings-collapse">
							<NavLink to="/settings/source"
									 className={(navInfo) => navInfo.isActive ? "nav-item sub nav-link active" : "nav-item sub nav-link"}>
								<i className="icofont-settings text-primary"></i>
								<span className="icon-text">Source settings</span>
							</NavLink>
						</div>
					</ui>


					<NavLink to="/pricing"
							 className={(navInfo) => navInfo.isActive ? "nav-item nav-link active" : "nav-item nav-link"}>
						<i className="icofont-price"></i>
						<span className="icon-text">Pricing</span>
					</NavLink>
				</nav>

				<div className="powered-by">Powered by <a href="https://prismict.com/" target="_blank" rel="noreferrer">prismict.com</a></div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	logout,
};
export default 
	connect(mapStateToProps, mapDispatchToProps)(SideMenu);
