import axios from 'axios';

const configureAxios = async (token) => {
	// axios.defaults.baseURL = 'http://localhost:9000';
	axios.defaults.baseURL = 'https://import-pro-api.prismict.com';

	let authToken = await localStorage.getItem('authToken')
	setAuthToken(authToken)
	return true
}


export const setAuthToken = async (token) => {

	if (token) {
		axios.defaults.headers.common['Authorization'] = token;
	} else {
		axios.defaults.headers.common['Authorization'] = '';
	}
	var clientTimezoneOffset = new Date().getTimezoneOffset() / 60;
	clientTimezoneOffset = parseInt(clientTimezoneOffset);
	if (clientTimezoneOffset > 0) {
		console.log('Positive');

		clientTimezoneOffset = "-" + Math.abs(clientTimezoneOffset)
	} else {
		console.log('Negative');
		clientTimezoneOffset = Math.abs(clientTimezoneOffset)
	}
	axios.defaults.headers.common['X-timezone'] = clientTimezoneOffset;
}

export default configureAxios
