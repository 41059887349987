import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import queryString from "query-string"

import { connect } from 'react-redux'
import { resetPassword } from '../../store/actions/authActions'
import bodyClassController from '../../store/utils/bodyClassController'
import setPageData from '../../store/actions/setPageData'

// Style assets
import '../../assets/styles/auth.scss'
import imgLogo from '../../assets/images/logo.png'

class ResetPasswordPage extends React.Component {
	state = {
		resetToken: '',
		password: '',
		confirmPassword: '',
		error: {},
		showTokenInput: true,
		isLoading: false,
	}

	static getDerivedStateFromProps(nextProps, prevProps) {
		if (JSON.stringify(nextProps.auth.error) !== JSON.stringify(prevProps.error)) {
			return {
				error: nextProps.auth.error
			}
		}
		return null
	}

	changeHandler = event => {
		this.setState({
			error: false
		})
		this.setState({
			[event.target.name]: event.target.value
		})
	}

	stopLoading = () => {
		this.setState({ isLoading: false })
	}

	submitHandler = event => {
		event.preventDefault()

		this.setState({ isLoading: true })

		let { resetToken, password, confirmPassword } = this.state
		this.props.resetPassword({ resetToken, password, confirmPassword }, this.props.history, this.stopLoading)
	}

	componentWillMount() {
	}

	redirectHomeIfLoggedin() {
		// Redirect home if already Loggedin
		let { isAuthenticated, authChecking } = this.props.auth
		if (!authChecking && isAuthenticated) {
			this.props.history.push('/')
		}
	}

	componentDidMount() {
		this.props.setPageData({
			title: 'Recover Your Password',
			pageID: 'forgot-password'
		});

		// Set reset token came from url
		const params = queryString.parse(this.props.location.search);
		if (params.token) {
			this.setState({
				resetToken: params.token,
				showTokenInput: false
			})
		}

	}
	render() {
		this.redirectHomeIfLoggedin()

		this.props.bodyClassController(this.props.pageData);
		let { resetToken, password, confirmPassword, error, showTokenInput, isLoading } = this.state
		return (
			<>
				<Helmet> <title>{'Recover Your Password'}</title> </Helmet>

				<div className="container-fluid">
					<div className="row page-auth">
						<div className="col-md-4 page-content">
							<div className="row">
								{/* <div className="col-md-5 login-preview">
								<div className="preview-photo">
									<img src={imgLoginPage} alt="Login SMM Panel Maker" srcset=""/>
								</div>
							</div> */}
								<div className="col-md-12 login-content">
									<div className="auth-brand">
										<img src={imgLogo} className="logo" alt="Make SMM Panel" />
									</div>

									{/* <h1 className="color-primary">Login</h1> */}
									<p className="text-center">Reset your password</p>
									<form onSubmit={this.submitHandler} className="">
										{showTokenInput === true && (
											<div className="form-group has-error">
												<input
													type="text"
													className={
														(error.resetToken ? "form-control is-invalid" : "form-control")
													}
													placeholder="Reset token"
													name="resetToken"
													id="resetToken"
													autoFocus={true}
													tabIndex='1'
													onChange={this.changeHandler}
													value={resetToken}
													required
												/>
												{error.resetToken && (
													<div className="invalid-feedback">
														{error.resetToken}
													</div>
												)}
											</div>
										)}

										<div className="form-group">
											<input
												type="password"
												className={
													(error.password ? "form-control is-invalid" : "form-control")
												}
												placeholder="New password"
												name="password"
												id="password"
												tabIndex='2'
												onChange={this.changeHandler}
												value={password}
												required
											/>
											{error.password && (
												<div className="invalid-feedback">
													{error.password}
												</div>
											)}
										</div>
										<div className="form-group">
											<input
												type="password"
												className={
													(error.confirmPassword ? "form-control is-invalid" : "form-control")
												}
												placeholder="Confirm password"
												name="confirmPassword"
												id="confirmPassword"
												tabIndex='2'
												onChange={this.changeHandler}
												value={confirmPassword}
												required
											/>
											{error.confirmPassword && (
												<div className="invalid-feedback">
													{error.confirmPassword}
												</div>
											)}
										</div>
										<div className="form-group form-support">
											<div className="text-muted">
												<Link to="/login" className="">Back to login</Link>
											</div>
										</div>

										<div className="text-right">
											<button
												type={isLoading ? 'button' : 'submit'}
												tabIndex='4'
												className={
													(error.error ? "btn btn-primary mb-2 px-5 btn-block is-invalid" : "btn btn-primary mb-2 px-5 btn-block")
												}> {isLoading ? (<i className="fas fa-spinner fa-spin"></i>) : 'Next'} </button>

											{error.error && (
												<div className="invalid-feedback text-center">
													{error.error}
												</div>
											)}
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
})

const mapDispatchToProps = ({
	resetPassword,
	setPageData,
	bodyClassController
})
// export default connect( mapStateToProps, {login} )(Login)
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage)
