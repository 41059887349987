import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";

import { createBrowserHistory } from "history";
// //

import "bootstrap";
import "jquery";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

import "./assets/styles/library/styles.scss"; // Library styles
import "./assets/styles/base/styles.scss"; // import base style

import MyRoutes from "./Routes";
import * as serviceWorker from "./serviceWorker";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Provider } from "react-redux";
import store from "./store";
const history = createBrowserHistory();

ReactDOM.render(
	<Provider store={store}>
		{/*<div>Testing..</div>,*/}
		{/*<Router history={history}>*/}
			<MyRoutes />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
			/>
		{/*</Router>*/}
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
