import React from "react";
import { connect } from "react-redux";
import 'rc-dropdown/assets/index.css';
import Switch from "react-switch";

import {putSettingsAction, retrieveSettingsAction} from "../../store/actions/settingsActions";

class SourceSettings extends React.Component {

	state = {
		promoCode: "",
		error: {},
		isLoading: false,
	};

	submitHandler = async (key, value) => {
		this.setState({ isLoading: true });

		let { type, typeID } = this.props;
		await this.props.putSettingsAction({
			type,
			typeID,
			key,
			value,
		});
		this.stopLoading();
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};
	handleSwitch = async (name, value) => {
		// console.log(value);
		this.setState({ [name] : value });
		this.submitHandler(name, value);
	};
	
	resetFormData = () => {
		this.setState({
			title: "",
			sourceUrl: "",
		});
	};

	async componentDidMount() {
		
		this.setExistsSettingsValue();
	}

	setExistsSettingsValue = async (name, value) => {
		// Load exists settings
		let { type, typeID } = this.props;
		this.setState({ screenLoading: true });
		let existsData = await this.props.retrieveSettingsAction({
			type,
			typeID
		});
		this.setState({ screenLoading: false });
		
		for (let ind in existsData){
			let single = existsData[ind]

			if(single.key === "importStatus"){
				this.setState({
					"importStatus" : single.value
				})
			}
			if(single.key === "insertToShopifyStatus"){
				this.setState({
					"insertToShopifyStatus" : single.value
				})
			}
			if(single.key === "autoUpdate"){
				this.setState({
					"autoUpdate" : single.value
				})
			}
			if(single.key === "autoUpdateTitle"){
				this.setState({
					"autoUpdateTitle" : single.value
				})
			}
			if(single.key === "autoUpdatePrice"){
				this.setState({
					"autoUpdatePrice" : single.value
				})
			}
			if(single.key === "autoUpdateInventory"){
				this.setState({
					"autoUpdateInventory" : single.value
				})
			}
		}
	};

	render() {
		let { screenLoading } = this.state;
		if(screenLoading === true){
			return (
				<div className="text-center p-5"><i className="fas fa-spinner fa-spin"></i> Loading...</div>
			);
		}else{
			return (
				<div className="settings-screen">
					<div className="row p-5">
						<div className="col-md-6 settings-section">
							<div className="section-header p-3">
								<h5 className="header-title">Basic Settings</h5>
							</div>
							<div className="section-options">
								{this.SingleSwitchComponent('importStatus', 'Import from source', this.state.importStatus)}
								{this.SingleSwitchComponent('insertToShopifyStatus', 'Insert to shopify', this.state.insertToShopifyStatus)}
							</div>
						</div>
						<div className="col-md-6 settings-section">
							<div className="section-header p-3">
								<h5 className="header-title">Auto store update</h5>
							</div>

							<div className="section-options">
								{this.SingleSwitchComponent('autoUpdate', 'Auto store update', this.state.autoUpdate)}
								{this.SingleSwitchComponent('autoUpdateTitle', '  - Update title', this.state.autoUpdateTitle)}
								{this.SingleSwitchComponent('autoUpdatePrice', '  - Update price', this.state.autoUpdatePrice)}
								{this.SingleSwitchComponent('autoUpdateInventory', '  - Update inventory', this.state.autoUpdateInventory)}
							</div>

						</div>
					</div>
				</div>
			);
		}
	}

	SingleSwitchComponent(name, title, curValue) {
		return (
			<div className="single-option">
				<div className="option-name">{title}</div>
				<div className="option-value">
					<Switch
						onChange={(val) => {
							this.handleSwitch(name, val)
						}}
						checked={curValue} />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	putSettingsAction,
	retrieveSettingsAction
};
export default connect(mapStateToProps, mapDispatchToProps)(SourceSettings);
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
