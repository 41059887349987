import React from "react";
import { connect } from "react-redux";
import {useNavigate} from "react-router-dom";
import { isLoggedIn } from "../../store/actions/authActions";
import configureAxios from "../../store/utils/configureAxios";

class TokenChecking extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			user: "",
			password: "",
			error: {},
		};
	}

	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	submitHandler = (event) => {
		event.preventDefault();

		this.props.logout(this.props.history);
	};

	async authCheck() {
		await configureAxios(); // Run Basic Axios Configure
		this.props.isLoggedIn(this.forceLogin);
	}

	forceLogin = () => {

		// let { isAuthenticated, authChecking } = this.props.auth;
		// if (!authChecking && !isAuthenticated) {
		// 	navigate("/login")
		// 	// .this.props.history.push("/login");
		// }
	};
	componentDidMount() {
		this.authCheck();
	}

	render() {
		// let { data-source, password, error } = this.state
		this.authCheck();
		return (
			<div className="page-auth-checking">
				<div className="checking-content">
					<div className="footer">
						<h2><i className="fas fa-spinner fa-spin"></i></h2>
						<h5>Validating access token...</h5>
					</div>
				</div>
			</div>
		);
	}
}


const mapStateToProps = (state) => ({
	auth: state.auth,
});
const mapDispatchToProps = {
	isLoggedIn,
};
export default connect(mapStateToProps, mapDispatchToProps)(TokenChecking);


