import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import SideMenu from "../../components/menu/sideMenu";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import NewSourceModal from "../../components/data-source/NewSourceModal";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import ShowProducts from "../products/ShowProducts";
import { useParams } from "react-router-dom";
import ShowFileDataMapping from "./ShowFileDataMapping";
import SourceSettings from "./SourceSettings";

function withParams(Component) {
	return props => <Component {...props} params={useParams()} />;
}

class SourceDetailsPage extends React.Component {
	insetModalStatus = false;
	constructor(props) {
		super(props);
		this.state = {
			insetModalStatus: false,

		};
	}

	setPageData() {
		this.props.setPageData({
			title: "Source details",
			pageID: "home_page",
			totalUsers: "...",
		});
	}

	componentDidMount() {
		this.setPageData();
	}

	componentWilMount() {}

	render() {
		this.props.bodyClassController(this.props.pageData);


		let { pageData, userData } = this.props;
		let { sourceID } = this.props.params;
		let { insetModalStatus, reloadDataID } = this.state;

		let userSummery;

		if (userData) {
			userSummery = userData.summery;
		}
		return (
			<>
				<Helmet><title>{pageData.title}</title></Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{pageData.title}</h1>
									</div>
								</div>

								<div className="page-content">
									<Tabs>
										<TabList>
											<Tab>Mapping</Tab>
											<Tab>Products</Tab>
											{/*<Tab>Split</Tab>*/}
											{/*<Tab>Filter</Tab>*/}
											{/*<Tab>Replace</Tab>*/}
											<Tab>Settings</Tab>
										</TabList>

										<TabPanel>
											<ShowFileDataMapping sourceID={sourceID} />
										</TabPanel>
										<TabPanel>
											<ShowProducts sourceID={sourceID} type="queued"/>
										</TabPanel>
										<TabPanel>
											<SourceSettings type="source" typeID={sourceID}/>
										</TabPanel>
									</Tabs>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}


const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
};
export default withParams(connect(mapStateToProps, mapDispatchToProps)(SourceDetailsPage));
