import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import SideMenu from "../../components/menu/sideMenu";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import {insertSubscriptionAction} from "../../store/actions/subscriptionActions";



const ShowSinglePlan = (props) => {
	let disabled = props.disabled;
	if(props.loading || props.currentPlan){
		disabled = true;
	}

	return <div className="card mb-3 box-shadow">
		<div className="card-header">
			<h4 className="my-0 font-weight-normal"> {props.title} </h4>
		</div>
		<div className="card-body">
			<h1 className="card-title pricing-card-title">
				{props.price}
				{props.billingType && <small className="text-muted">/ {props.billingType}</small>}
			</h1>
			<ul className="list-unstyled mt-3 mb-4">
				<li>SKU Limit: {props.skuLimit}</li>
				<li>Feed/supplier: {props.feedLimit}</li>
				{props.dailyUpdate > 0 && <li>Daily update: {props.dailyUpdate}</li>}
				{props.UpdateEvery > 0 && <li>Update every {props.UpdateEvery} hours</li>}
				{props.emailSupport === true && <li>Support by email</li>}
			</ul>
			<button type="button"
					onClick={props.onClick}
					className={`btn btn-lg btn-primary ${disabled && 'disabled'}`}>

				{props.loading ? (
					<i className="fas fa-spinner fa-spin"></i>
				) : props.currentPlan ? "Current plan" :props.buttonTitle }


				{/*{props.buttonTitle}*/}

			</button>
		</div>
	</div>
}

class PricingPage extends React.Component {
	insetModalStatus = false;
	constructor(props) {
		super(props);
		this.state = {
			insetModalStatus: false,
			billingTypeYearly: false,
		};
	}

	setPageData() {
		this.props.setPageData({
			title: "Pricing",
			pageID: "pricing_page",
			totalUsers: "...",
		});
	}

	componentDidMount() {
		this.setPageData();
	}

	componentWilMount() {}

	handleChange(billingTypeYearly) {
		this.setState({ billingTypeYearly: billingTypeYearly });
	}

	async onSubmitSubscription(planID) {
		let { billingTypeYearly } = this.state;
		let billingType = billingTypeYearly !== true ?"monthly":"monthly";

		// Enable loading status
		this.setState({loadingSubscriptionID: planID})

		// Send request to server
		let resResult = await this.props.insertSubscriptionAction({
			planID,
			billingType,
		});

		// Remove loading status
		this.setState({loadingSubscriptionID: null})
	}

	render() {
		this.props.bodyClassController(this.props.pageData);
		let userSubscription;
		let { pageData, auth } = this.props;
		let { billingTypeYearly, loadingSubscriptionID } = this.state;

		let billingType = "month";
		if(billingTypeYearly === true){
			billingType = "year";
		}
		if(auth){
			userSubscription = auth.user.subscription;
		}

		return (
			<>
				<Helmet><title>{pageData.title}</title></Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />
						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>Pricing Plans</h1>
									</div>
									<span
										className="d-flex ml-3">
										Billing Type: Monthly Recurring
										{/*<div*/}
										{/*	className={`my-switch ${billingTypeYearly == true && "active" }`}>*/}
										{/*	<div className={`switch-item ${billingTypeYearly != true && "active" }`}>Monthly</div>*/}
										{/*	<div*/}
										{/*		onClick={() => {*/}
										{/*			this.handleChange(!billingTypeYearly);*/}
										{/*		}}*/}
										{/*		className="switch-indicator"></div>*/}
										{/*	<div className={`switch-item ${billingTypeYearly == true && "active" }`}>Yearly (save 17%)</div>*/}
										{/*</div>*/}
									</span>
								</div>

								<div className="page-content bg-transparent">
									<div className="pricing-table card-deck mb-3 text-center">
										<ShowSinglePlan
											title={"FREE PLAN"}
											price={"FREE"}
											disabled={loadingSubscriptionID}
											skuLimit={20}
											feedLimit={1}
											dailyUpdate={0}
											UpdateEvery ={0}
											emailSupport={0}
											currentPlan={userSubscription === null}
											buttonTitle ={userSubscription ? "Downgrade" : "Always Free"}
											onClick = {() => this.onSubmitSubscription('free')}/>

										<ShowSinglePlan
											title={"STARTER"}
											price={billingTypeYearly ? '$100' : '$10'}
											billingType={billingType}
											disabled={loadingSubscriptionID}
											skuLimit={1500}
											feedLimit={3}
											dailyUpdate={2}
											UpdateEvery ={12}
											emailSupport={true}
											currentPlan={userSubscription && userSubscription.planID === "starter"}
											loading={loadingSubscriptionID == 'starter'}
											buttonTitle = "Get started"
											onClick = {() => this.onSubmitSubscription('starter')}/>

										<ShowSinglePlan
											title={"PRO"}
											price={billingTypeYearly ? '$250' : '$25'}
											billingType={billingType}
											disabled={loadingSubscriptionID}
											skuLimit={3000}
											feedLimit={6}
											dailyUpdate={4}
											UpdateEvery ={6}
											emailSupport={true}
											currentPlan={userSubscription && userSubscription.planID === "pro"}
											loading={loadingSubscriptionID == 'pro'}
											buttonTitle = "Get started"
											onClick = {()=>this.onSubmitSubscription('pro')}/>

										<ShowSinglePlan
											title={"ADVANCE"}
											price={billingTypeYearly ? '500' : '$50'}
											billingType={billingType}
											disabled={loadingSubscriptionID}
											skuLimit={50000}
											feedLimit={30}
											dailyUpdate={6}
											UpdateEvery ={4}
											emailSupport={true}
											currentPlan={userSubscription && userSubscription.planID === "advance"}
											loading={loadingSubscriptionID == 'advance'}
											buttonTitle = "Get started"
											onClick = {()=>this.onSubmitSubscription('advance')}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}


const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
	insertSubscriptionAction
};
export default connect(mapStateToProps, mapDispatchToProps)(PricingPage);
