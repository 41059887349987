import React from "react";
import { connect } from "react-redux";
import 'rc-dropdown/assets/index.css';
import {newSourceAction, retrieveSourceDetails, putSourceMapAction} from "../../store/actions/productActions";

class ShowFileDataMapping extends React.Component {

	state = {
		error: {},
		isLoading: false,
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};
	
	submitHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		let { sourceID } = this.props;
		console.log(this.state);
		let {
			products,
			product_id,
			sku,
			title,
			body_html,
			inventory,
			price } = this.state;

		await this.props.putSourceMapAction({
			sourceID,
			products,
			product_id,
			sku,
			title,
			body_html,
			inventory,
			price
		});

		this.stopLoading();
		
	};

	componentDidMount() {
		let { preData } = this.props;

		// set default info if data provided
		if (preData) {
			this.setState({
				promoAuthor: preData.promoAuthor,
			});
		}
		this.getSourceDetails();
	}

	getSourceDetails = async () => {
		let { sourceID } = this.props;
		this.setState({ screenLoading: true });
		let sourceDetails = await this.props.retrieveSourceDetails({
			sourceID:sourceID
		});

		this.setState({
			sourceDetails: sourceDetails,
			products : sourceDetails.map.products,
			product_id : sourceDetails.map.product_id,
			sku : sourceDetails.map.sku,
			title : sourceDetails.map.title,
			body_html : sourceDetails.map.body_html,
			inventory : sourceDetails.map.inventory,
			price : sourceDetails.map.price,
		})



		this.setState({ screenLoading: false });
	};


	render() {
		let { isLoading, error } = this.state;
		let { screenLoading } = this.state;

		if(screenLoading === true){
			return (
				<div className="text-center p-5"><i className="fas fa-spinner fa-spin"></i> Loading...</div>
			);
		}else{
			return (
				<form onSubmit={this.submitHandler} action="">
					<div className="row">
						<div className="col-md-12">
							<div className="single-section p-5">
								
								{/* name, title, hasError, curValue */}

								{this.SingleSelectComponent('products', 'Products dom')}
								{this.SingleSelectComponent('product_id', 'Unique value')}
								{this.SingleSelectComponent('sku', 'Product SKU')}
								{this.SingleSelectComponent('title', 'Title')}
								{this.SingleSelectComponent('body_html', 'Description')}
								{this.SingleSelectComponent('inventory', 'Inventory')}
								{this.SingleSelectComponent('price', 'Price')}
								
								<div className="form-actions text-right row">
									<div className="col-md-11">
										<button
											type={isLoading ? "button" : "submit"}
											className="btn btn-primary is-invalid"
										>
											{isLoading ? (
												<i className="fas fa-spinner fa-spin"></i>
											) : (
												"Update map"
											)}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			);
		}
	}


	SingleSelectComponent(name, title) {
		let {sourceDetails} = this.state
		// console.log(sourceDetails);

		let hasError
		
		let dataTreeList, mapDetails, curValue;

		if(sourceDetails){
			if(sourceDetails && sourceDetails.source){
				dataTreeList = sourceDetails.source.dataTreeList
			}
			if(sourceDetails && sourceDetails.map){
				mapDetails = sourceDetails.map
			}
		}
		curValue = this.state[name];

		return (
			<div className="form-group has-error row">
				<label className="bmd-label-static col-md-3 col-form-label text-right" for={`id_${name}`}>{title}</label>
				<div className="col-md-8">
					<select
						className={
							hasError
								? "form-control is-invalid"
								: "form-control"
						} id={`id_${name}`}
						value={curValue}
						name={name}
						onChange={this.changeHandler}>

						<option value="">Select one</option>

						{dataTreeList
							? dataTreeList.map((item, index) => {
								item = dataTreeList[index]
								return (
									<option value={item}>{item}</option>
								)
							})
							: <option value="">Empty</option>}
					</select>
					{hasError && (
						<div className="invalid-feedback">
							{hasError}
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	newSourceAction,
	retrieveSourceDetails,
	putSourceMapAction
};
export default connect(mapStateToProps, mapDispatchToProps)(ShowFileDataMapping);
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
