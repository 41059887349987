import React from "react";
import { connect } from "react-redux";
import {newSourceAction} from "../../store/actions/productActions";

class NewSourceForm extends React.Component {
	state = {
		promoCode: "",
		error: {},
		isLoading: false,
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	resetFormData = () => {
		this.setState({
			title: "",
			sourceUrl: "",
		});
	};

	submitHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		let { title, sourceUrl } = this.state;
		let resResult = await this.props.newSourceAction({
			title,
			sourceUrl,
		});
		this.stopLoading();
		if(resResult == true){
			this.resetFormData();
		}
	};

	componentDidMount() {
		let { preData } = this.props;

		// set default info if data provided
		if (preData) {
			this.setState({
				promoAuthor: preData.promoAuthor,
			});
		}
	}

	render() {
		let { isLoading, error } = this.state;

		return (
			<form onSubmit={this.submitHandler} action="">
				<div className="row">
					<div className="col-md-12">
						<div className="single-section">
							<div className="form-group has-error">
								<input
									type="text"
									className={
										error.title
											? "form-control is-invalid"
											: "form-control"
									}
									name="title"
									id="title"
									value={this.state.title}
									onChange={this.changeHandler}
									required={true}
									placeholder={`Source title (Ex. Prism Store Data)`}
								/>
								{error.title && (
									<div className="invalid-feedback">
										{error.title}
									</div>
								)}
							</div>
							<div className="form-group has-error">
								<input
									type="url"
									className={
										error.sourceUrl
											? "form-control is-invalid"
											: "form-control"
									}
									name="sourceUrl"
									id="sourceUrl"
									value={this.state.sourceUrl}
									onChange={this.changeHandler}
									required={true}
									placeholder={`Enter product feed/xml URL ...`}
								/>
								{error.sourceUrl && (
									<div className="invalid-feedback">
										{error.sourceUrl}
									</div>
								)}
							</div>

							<div className="form-actions text-right">
								<button
									type={isLoading ? "button" : "submit"}
									className="btn btn-primary is-invalid"
								>
									{" "}
									{isLoading ? (
										<i className="fas fa-spinner fa-spin"></i>
									) : (
										"Add source"
									)}{" "}
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	newSourceAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewSourceForm);
