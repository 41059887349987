import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import SideMenu from "../../components/menu/sideMenu";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";
import { useParams } from "react-router-dom";

import setPageData from "../../store/actions/setPageData";
import SourceSettings from "../data-source/SourceSettings";


function withParams(Component) {
	return props => <Component {...props} params={useParams()} />;
}
class ProductSettingsPage extends React.Component {
	detailsModalStatus = false;
	constructor(props) {
		super(props);
		this.state = {
			insetModalStatus: false,
		};
	}

	setPageData() {
		let { type } = this.props
		this.props.setPageData({
			title: type == "queued" ? "Queued products" :
					type == "imported" ? "Imported products" :
						type == "failed" ? "Failed products" : "All products",
			pageID: "products_page",
			totalUsers: "...",
		});
	}

	componentDidMount() {
		this.setPageData();
	}

	componentWilMount() {}
	componentDidUpdate(prevProps) {
		if(prevProps.type != this.props.type){
			setPageData()
		}
	}

	openInsertModal() {
		this.setState({
			detailsModalStatus: true,
		});
	}
	closeInsertModal() {
		this.setState({
			detailsModalStatus: false,
		});
	}

	render() {
		this.props.bodyClassController(this.props.pageData);
		let { pageData, userData } = this.props;
		let { productID } = this.props.params;

		let { insetModalStatus } = this.state;

		let userSummery;

		if (userData) {
			userSummery = userData.summery;
		}
		let {type} = this.props;
		let pageTitle = `Product settings`;
		return (
			<>
				<Helmet><title>{pageTitle}</title></Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{pageTitle}</h1>
									</div>
								</div>

								<div className="page-content">
									<SourceSettings type="product" typeID={productID}/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}


const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
};
export default withParams(connect(mapStateToProps, mapDispatchToProps)(ProductSettingsPage));
