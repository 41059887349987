import React from "react";
import { connect } from "react-redux";
import { isLoggedIn } from "../../store/actions/authActions";
import configureAxios from "../../store/utils/configureAxios";
import imgLogo from '../../assets/images/logo_in_black.png'

class AuthRequired extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			user: "",
			password: "",
			error: {},
		};
	}

	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	submitHandler = (event) => {
		event.preventDefault();

		this.props.logout(this.props.history);
	};

	async authCheck() {
		await configureAxios(); // Run Basic Axios Configure
		this.props.isLoggedIn(this.forceLogin);
	}

	forceLogin = () => {

		// let { isAuthenticated, authChecking } = this.props.auth;
		// if (!authChecking && !isAuthenticated) {
		// 	navigate("/login")
		// 	// .this.props.history.push("/login");
		// }
	};
	componentDidMount() {
		// this.authCheck();
	}

	render() {
		// let { data-source, password, error } = this.state
		// this.authCheck();
		return (
			<div className="page-auth-required">
				<div className="auth-brand">
					<img src={imgLogo} className="logo" alt="Make SMM Panel" />
				</div>

				<h1 className="title">Welcome to Prism Product Importer!</h1>

				<div className="body-content">
					<p>
						Before you can access this page, please make sure you have installed the app on your Shopify store. To get started, simply click on the "Get App" button in the Shopify App Store and install "<b>Prism - Product Importer</b>" on your store.
					</p>

					<p>
						Once you have installed the app, you can access this page by clicking on the "<b>Use App</b>" button. This will bring you to the "Prism - Product Importer" dashboard, where you can import products from third-party suppliers with ease.
					</p>
				</div>
			</div>
		);
	}
}


const mapStateToProps = (state) => ({
	auth: state.auth,
});
const mapDispatchToProps = {
	isLoggedIn,
};
export default connect(mapStateToProps, mapDispatchToProps)(AuthRequired);


